import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Cta from "./Cta";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section ",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <div className="spacer-desktop-md" />
            <div className="spacer-desktop-md" />
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              a simple, weekly sms.{"\n"}
              <div style={{ height: "25px" }} />
              {/* TODO: more handrawn css effect. similar to sqaurespace https://plums-mandolin-2hbb.squarespace.com/ */}
              <span className="highlight">more mindful screentime</span>.
            </h1>
            <br />
            <br />

            <img
              className="reveal-from-bottom screenshot"
              data-reveal-delay="600"
              src="/landing_screenshot.png"
              alt="mindful text convo. spend less time on instagram"
              style={{
                filter: "drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.22))",
              }}
            />
          </div>
        </div>
        <Cta split />
        <div style={{ height: "100px" }} />
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
