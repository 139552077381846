import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import { showNotification } from "@mantine/notifications";
import { Flex } from "@mantine/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const makeWaitlistApiRequest = async (phoneNumber) => {
  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:8000";
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/user/waitlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ phoneNumber }),
    });
    const data = await response.json();
    if (data.success) {
      showCompleteNotification(data.notification);
    } else {
      showErrorNotification(data.notification);
    }
  } catch (error) {
    // default error message
    showErrorNotification({
      title: "whoops. tiny error",
      message: "we're looking into it. it may be on our end. please try again?",
    });
  }
};

const showCompleteNotification = (notification) => {
  showNotification({
    title: notification.title,
    message: notification.message,
    styles: (theme) => ({
      root: {
        backgroundColor: theme.colors.green[6],
        borderColor: theme.colors.green[6],
        "&::before": { backgroundColor: theme.white },
      },

      title: { color: theme.white },
      description: { color: theme.white },
      closeButton: {
        color: theme.white,
        "&:hover": { backgroundColor: theme.colors.green[7] },
      },
    }),
  });
};

const showErrorNotification = (notification) => {
  showNotification({
    title: notification.title,
    message: notification.message,
    styles: (theme) => ({
      root: {
        backgroundColor: theme.colors.red[6],
        borderColor: theme.colors.red[6],
        "&::before": { backgroundColor: theme.white },
      },

      title: { color: theme.white },
      description: { color: theme.white },
      closeButton: {
        color: theme.white,
        "&:hover": { backgroundColor: theme.colors.red[7] },
      },
    }),
  });
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const outerClasses = classNames(
    "cta reveal-from-bottom",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );
  const [phoneNumber, setPhoneNumber] = useState();
  const [country, setCountry] = useState();
  const handleOnWaitlistSubmit = useCallback(() => {
    const formattedPhoneNumber = `+${phoneNumber}`;
    makeWaitlistApiRequest(formattedPhoneNumber);
    setPhoneNumber("");
  }, [phoneNumber]);

  return (
    <section {...props} className={outerClasses} data-reveal-delay="1000">
      <Flex className="cta-container">
        <PhoneInput
          containerStyle={{
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            borderColor: "white",

            backgroundColor: "transparent",
          }}
          buttonStyle={{
            backgroundColor: "transparent",
            borderColor: "none",
            display: country ? "block " : "none",
            border: 0,
            borderRight: "1px solid #F4E0DC",
          }}
          inputStyle={{
            height: "100%",
            width: "100%",
            fontFamily: "montserrat",
            borderRadius: 0,
            border: 0,
            borderTopLeftRadius: 30,
            borderBottomLeftRadius: 30,
          }}
          onFocus={() => {
            if (!country && !phoneNumber) setCountry("us");
          }}
          preferredCountries={["gb", "ie", "mx", "ca", "us"]}
          country={country}
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={setPhoneNumber}
        />
        <button type="submit" onClick={handleOnWaitlistSubmit}>
          join waitlist
        </button>
      </Flex>
      <div style={{ height: "50px" }} />
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
